<template>
    <v-container fluid>
        <v-data-table
            :loading="loading"
            :headers="headers"
            :items="filteredData"
            class="elevation-1"
            fixed-header
            :height="$vuetify.breakpoint.mdAndDown ? '66vh' : '68vh'"
            hide-default-footer
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-2 pb-0 pb-0"
                    style="background-color: #eeeeee"
                >
                    <v-col cols="12" xl="4" lg="4" md="4"
                        ><h1 class="mr-4 my-0">Work Hours Per Week</h1></v-col
                    >
                    <v-spacer></v-spacer>
                    <v-col cols="12" xl="4" lg="4" md="4">
                        <v-select
                            v-model="weekFilter"
                            :items="availableWeeks"
                            label="Select Week"
                            @change="filterTable"
                            class="mt-2"
                            max-width="100"
                        ></v-select>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:[`item.name`]="{ item }">
                <strong>{{ item.name }}</strong>
            </template>

            <template v-for="day in days" v-slot:[`item.${day}`]="{ item }">
                <v-tooltip bottom :key="day">
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on"
                            >{{
                                formatNumber(item.timesheet[day]) || 0
                            }}
                            Hours</span
                        >
                    </template>
                    <span v-if="item.rawTimesheet[day]">
                        <div
                            v-for="record in item.rawTimesheet[day]"
                            :key="record.open"
                        >
                            {{ record.open }} - {{ record.close }}
                        </div>
                    </span>
                </v-tooltip>
            </template>

            <template v-slot:[`item.totalHours`]="{ item }">
                <strong>{{ formatNumber(item.totalHours) }} Hours</strong>
            </template>
        </v-data-table>
        <!--ERRORS ALERT-->
        <Errors />
    </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import Errors from '@/components/Layout/Errors'
import moment from 'moment'

export default {
    name: 'WeeklyTimeKeepingReports',
    props: {
        project: {
            type: Object,
            required: true,
        },
    },
    components: {
        Errors,
    },
    data() {
        return {
            loading: false,
            weekFilter: this.getCurrentWeek(),
            availableWeeks: Array.from({ length: 52 }, (_, i) => i + 1),
            headers: [
                {
                    text: 'User',
                    value: 'name',
                    sortable: false,
                    align: 'center',
                },
                { text: 'Monday', value: 'Monday', align: 'center' },
                { text: 'Tuesday', value: 'Tuesday', align: 'center' },
                { text: 'Wednesday', value: 'Wednesday', align: 'center' },
                { text: 'Thursday', value: 'Thursday', align: 'center' },
                { text: 'Friday', value: 'Friday', align: 'center' },
                { text: 'Saturday', value: 'Saturday', align: 'center' },
                { text: 'Sunday', value: 'Sunday', align: 'center' },
                { text: 'Total Hours', value: 'totalHours', align: 'center' },
            ],
            days: [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday',
            ],
            rawData: [],
            filteredData: [],
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        async getData() {
            try {
                this.rawData = await API.getExternalUsers({
                    projectId: this.project.id,
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        filterTable() {
            const week = parseInt(this.weekFilter, 10)
            if (isNaN(week)) {
                this.filteredData = this.rawData
                return
            }
            const year = new Date().getFullYear()
            const weekStart = this.getWeekStartDate(year, week)
            const weekDates = this.getWeekDates(weekStart)

            this.filteredData = this.rawData.map(user => {
                const timesheet = {}
                const rawTimesheet = {}
                let totalHours = 0
                weekDates.forEach((date, index) => {
                    const records = user.timesheet[date] || []
                    const hours = this.calculateHours(records)
                    timesheet[this.days[index]] = hours
                    rawTimesheet[this.days[index]] = records.map(record => {
                        return {
                            open: moment
                                .unix(record.clockIn._seconds)
                                .format('HH:mm'),
                            close: record.clockOut
                                ? moment
                                      .unix(record.clockOut._seconds)
                                      .format('HH:mm')
                                : 'inProgress',
                        }
                    })
                    totalHours += hours
                })
                return { ...user, timesheet, rawTimesheet, totalHours }
            })
        },
        getCurrentWeek() {
            const now = new Date()
            const startOfYear = new Date(now.getFullYear(), 0, 1)
            const pastDays = (now - startOfYear) / 86400000
            return Math.ceil((pastDays + startOfYear.getDay() + 1) / 7)
        },
        getWeekStartDate(year, week) {
            const firstDayOfYear = new Date(year, 0, 1)
            const daysOffset = (week - 1) * 7 - firstDayOfYear.getDay()
            return new Date(year, 0, 1 + daysOffset)
        },
        getWeekDates(startDate) {
            return Array.from({ length: 7 }, (_, i) => {
                const date = new Date(startDate)
                date.setDate(startDate.getDate() + i)
                return date.toISOString().split('T')[0]
            })
        },
        calculateHours(records) {
            return records.reduce((total, record) => {
                const open = new Date(record.clockIn._seconds * 1000)
                if (!record.clockOut) {
                    return total + 0
                }
                const close = new Date(record.clockOut._seconds * 1000)
                return total + (close - open) / 3600000
            }, 0)
        },
        formatNumber(number) {
            return number.toFixed(2)
        },
    },
    async mounted() {
        try {
            this.loading = true
            await this.getData()
            this.filterTable()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
}
</script>

<style></style>
